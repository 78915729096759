.layout-wrapper {
  .ant-layout-header {
    background-color: #fff;
  }
  .ant-layout-sider-children {
    background-color: #fff;
  }
  .ant-layout-sider-trigger {
    background-color: #fff;
    border-top: 1px solid $border-color;
    .anticon {
      color: $primary-color;
    }
  }
  .logo {
    width: 80px;
    margin: 3px 45px;
  }
  .menu-title {
    font-weight: 600;
    display: block;
    padding: 8px 15px;
  }
  .ant-breadcrumb {
    ol {
      padding-left: 0px !important;
    }
  }
  .nav-menu {
    i {
      font-size: 18px;
    }
  }
  .header {
    .ant-avatar {
      background-color: $primary-color;
      margin-right: 20px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.ant-card-body {
  padding-bottom: 0px;
}
